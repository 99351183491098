import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import title_image from '../images/titles/about.jpg'

const pageTitle = "About Nuerex";
const pageSlug = "about-us";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="about-us">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Our organizational culture is on par with our business strategy.</h6>
        </div>
      </section>

      <section id="p_about" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    We’re constantly striving to do better at Nuerex.<br />Be close to your colleagues, 
                    have fun, and do your best.
                    </h3>
                   <p>This is the foundation for our consulting methods and has created a workplace culture 
                       that unifies our team and leads to constant collaboration. We enjoy working at Nuerex 
                       to develop personalized solutions for your business. Discover more about who we are, 
                       and what it means to work with us, as well as our core values.</p>
                    <span className="break"></span>
                    <h4>Our Journey</h4>
                    <p>We wanted to create a consulting service that allows us to make a better impact on 
                        others and deliver a quality service.</p>
                    <span className="break"></span>
                    <h4>Our Workplace Beliefs</h4>
                    <p>We believe that consulting is a group activity, which is why we do not just work for 
                        you – we work with you. To create and discover new ideas and unique, fresh 
                        perspectives that allow for more significant optimization in your processes, 
                        elevating your technology, giving you a competitive advantage in an ever-changing 
                        digital world.</p>

                    <span className="break"></span>
                <h4>Why We Exist</h4>
                <p>We believe that both our clients and employees deserve quality and top-grade experiences. 
                    That’s why we are here – to provide a unique, unmatched experience. Discover more about 
                    what motivates and defines us and how we continuously strive for the best.</p>

                    <span className="break"></span>
                <h4>What We Give</h4>
                <p>Working for the Greater Good is one of our core passions. We strive to make a positive 
                    impact on all of our partners. Discover how we can make an impact for the better.</p>

                    <span className="break"></span>
                <h4>Our Leadership</h4>
                <p>Nuerex’s decision-makers have a vast amount of expertise and consider all available 
                    perspectives. We are firmly committed to doing what’s best for our clients and people 
                    in a way that is unmatched by any other organization. This central focus, combined with 
                    the characteristics of our people is how we grow and serve you better.</p>

                <p>We strive to give you an unmatched experience. We work with you to do what’s best for you.</p>

                <span className="break"></span>
                <h4>Our latest blogs</h4>
                <p>Find relevant and concise information in our blog posts.  It is our pleasure to provide 
                    any relevant information that can help you make the right decisions.</p>
                <span className="break"></span>
                <h4>Careers</h4>
                <p>We strive to create an environment that values our employees and empowers them to do 
                    their best work. We hope to create a culture that allows them to balance their 
                    working and free time.</p>
                <p>Human resources are our greatest recourse. We value our hiring process as it starts 
                    with our core values. We hope to hire people that share our commitment towards 
                    quality and best practices.</p>
                <span className="break"></span>
                <h4>Diversity and Inclusion</h4>
                <p>We strive to bring professionals from a variety of different backgrounds together, each with 
                    their own distinct, skills approaches, and personal experiences. This ability to channel diverse 
                    opinions and thoughts allows us to challenge norms and create intuitive solutions to problems and 
                    become stronger leaders.</p>
                <span className="break"></span>
                <h3>At Nuerex we value our clients and partners</h3>
                <span className="break"></span>
                <p>The people we work with make us who we are, and we are always striving to deliver the best solutions.</p>
                    <span className="break"></span>
                    <div className="contact-box">
                    <h3>By working together, we can uncover answers so that you can become more confident, aware, 
                        and have a lasting personalized solution that adapts to your situations.</h3>  
                    <span className="break"></span>
                    <div className="button-area">
                        <Link to="/contact-us" className="button default">Contact us</Link>
                    </div>
                    </div>
                </div>
               
            </div>
        </div>
      </section>

      </Layout>
    )
  }
}

export default BusinessConsulting
